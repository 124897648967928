import {map} from 'lodash';

export default function convertChevrotainErrors(lexErrors, parseErrors) {
  return [
    ...map(lexErrors, (error) => {
      const {message, column, line, length} = error;
      return {
        type: 'error',
        text: message,
        startRow: line - 1,
        endRow: line - 1,
        startCol: column - 1,
        endCol: column - 1 + length,
      };
    }),
    ...map(parseErrors, (error) => {
      const {message, token, previousToken} = error;
      return {
        type: 'error',
        text: message,
        startRow: (token?.startLine || previousToken?.startLine) - 1,
        endRow: (token?.endLine || previousToken?.endLine) - 1,
        startCol: (token?.startColumn || previousToken?.startColumn) - 1,
        endCol: (token?.endColumn || previousToken?.endColumn),
      };
    }),
  ];
}
